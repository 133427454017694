// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_aEUbJL4gZbNT5mo00U", // colb
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/cN27tl4Jgd7M4AE4ha", // colb

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Coloring Book Style Art GenAI 🎨",
    HOME_PAGE_TITLE: "Home - Coloring Book Style Art GenAI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Coloring Book Style Art GenAI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Coloring Book Style Art GenAI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate coloring book style images, choose an image and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "coloring_book_art_gen_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/coloring-book-art-gen-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;